import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Area, Beds, Baths } from '../components/icons'
import Link from '../utils/link'
import Fade from 'react-reveal/Fade'
import BoatCard from '../components/cards/boat'
import FsLightbox from 'fslightbox-react'

import Seo from '../components/seo'
import FormCta from '../components/form-cta'

class BoatTemplate extends Component {

  state = {
    imageLightbox: false
  }

  render() {
    let page = this.props.data.wpBoat
    let similarBoats = this.props.data.allWpBoat
    let agent = page.acf.agent?.length > 0 && page.acf.agent[0]
    let { imageLightbox } = this.state

    const settings = {
      infinite: true,
      speed: 600,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      adaptiveHeight: true,
    }

    return (
      <>
        <Seo title={page.seo?.title} bodyClass={'single-boat'} description={page.seo?.metaDesc} />
        <section className='single-boat__gallery'>
          <Slider {...settings}>
            { page.acf.gallery?.length > 0 && page.acf.gallery.map((el, i) => (
              <GatsbyImage image={getImage(el.localFile)} alt={page.title} key={i} />
            ))}
          </Slider>
        </section>
        <section className='single-boat__content'>
          <div className='single-boat__inner'>
            <div className='single-boat__info'>
              <h1>{page.title}</h1>
              <h3>{page.acf.price.displayPrice}</h3>
              <div className='boat__meta'>
                <ul>
                  <li>
                    <Area color={'#000000'} />
                    <span>{page.acf.specs.size}</span>
                  </li>
                  <li>
                    <Beds color={'#000000'} />
                    <span>{page.acf.specs.beds}</span>
                  </li>
                  <li>
                    <Baths color={'#000000'} />
                    <span>{page.acf.specs.baths}</span>
                  </li>
                </ul>
              </div>
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
              { page.acf.secondGallery?.length > 0 &&
                <div className='single-boat__second-gallery'>
                  <Slider {...settings}>
                    { page.acf.secondGallery.map((el, i) => (
                      <GatsbyImage image={getImage(el.localFile)} alt={page.title} key={i} onClick={() => this.setState({ imageLightbox: !imageLightbox })} />
                    ))}
                  </Slider>
                </div>
              }
            </div>
            <div className='single-boat__specs'>
              <div className='single-boat__agent'>
                <h4>Contact us today</h4>
                <GatsbyImage image={getImage(agent.featuredImage?.node?.localFile)} alt={agent.title} />
                <p>
                  <strong>{agent.title}</strong><br />
                  <Link to={agent.acf?.mobile?.link}>{agent.acf?.mobile?.text}</Link><br />
                  <Link to={`mailto:${agent.acf?.email}`}>{agent.acf?.email}</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='featured-properties'>
          <Fade bottom distance='30px'>
            <div className='featured-properties__inner'>
              <div className='featured-properties__title'>
                <h3>Similar Boats</h3>
              </div>
              <div className='featured-properties__items'>
                { similarBoats && similarBoats.nodes.length > 0 &&
                   <Slider {...settings}>
                    { similarBoats.nodes.map((el, i) => (
                      <BoatCard feature={true} key={i} {...el} />
                    ))}
                  </Slider>
                }
              </div>
            </div>
          </Fade>
        </section>
        <section className='cta-form'>
          <StaticImage className='cta-form__bg' src={`../assets/images/cta-blue.jpg`} alt={page.title} />
          <div className='cta-form__inner'>
            <div className='cta-form__content'>
              <h3>Interested in the {page.title}?</h3>
              <div>
                <p>Get in touch with us to discuss this or any other boat. We’ll get back to you shortly.</p>
              </div>
            </div>
            <div className='cta-form__form'>
              <FormCta boat={page.title} />
            </div>
          </div>
        </section>
        { page.acf.secondGallery?.length > 0 &&
          <FsLightbox
            toggler={imageLightbox}
            sources={page.acf.secondGallery.map((el, i) => {
              return (
                <img src={el.localFile?.childImageSharp?.original?.src} alt={page.title} key={i} />
              )
            })}
          />
        }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpBoat(id: {eq: $id}) {
      title
      slug
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 1400
                formats: [AUTO, WEBP, AVIF]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        subtitle
        secondGallery {
          localFile {
            childImageSharp {
              original {
                src
              }
              gatsbyImageData(
                quality: 100
                width: 1400
                formats: [AUTO, WEBP, AVIF]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        specs {
          size
          beds
          baths
        }
        price {
          searchPrice
          displayPrice
        }
        boatType
        boatStatus
        excerpt
        agent {
          ... on WpAgent {
            title
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 400
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            acf {
              mobile {
                text
                link
              }
              email
            }
          }
        }
      }
    }
    allWpBoat(
      filter: {id: {ne: $id}}
      limit: 3
    ) {
      nodes {
        title
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 600
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        acf {
          specs {
            size
            beds
            baths
          }
          price {
            searchPrice
            displayPrice
          }
          boatStatus
          boatType
          excerpt
        }
      }
    }
  }
`

export default BoatTemplate
